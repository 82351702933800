import {initializeApp} from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const app = initializeApp({
    projectId: process.env.REACT_APP_PROJECT_ID,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
});

export const functions = getFunctions(app);
const sendEmailFunc = httpsCallable(functions, 'sendEmail');
export const sendEmail = async (name: string, email: string, message: string, recaptchakey: string) => {
    const res = await sendEmailFunc({
        name,
        email,
        message,
        recaptcha: recaptchakey
    });
    const responseText = res.data;
    if (responseText === 'success') {
        return {
            success: false,
            message: responseText
        }
    }
    return {
        success: res.data === 'success',
        message: null
    }
}
